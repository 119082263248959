import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const Blog1 = () => {
  return (
    <div
      style={{
        padding: "5% 15%",
        lineHeight: "1.8",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Title level={2} style={{ textAlign: "center", marginBottom: "5%" }}>
        Revolutionize Your Investment Decisions with Our Advanced Stock Screener
      </Title>

      <Paragraph>
        Investing in the stock market requires reliable tools to navigate
        complex data. Our <strong>AI-powered Stock Screener</strong> is built to
        deliver accurate, actionable insights based on real-time data and
        credible sources. Whether you're a seasoned investor or a beginner, our
        solution empowers you to make confident, data-driven decisions.
      </Paragraph>

      <Title level={4} style={{ marginTop: "5%" }}>
        Key Features of Our Stock Screener
      </Title>

      <Paragraph>
        <strong>1. Sources - No Black Box</strong>
        <br />
        We provide credible sources for every answer, including SEC filings such
        as 10-K, 10-Q, and earnings transcripts. Each answer is backed by a
        direct URL to the source, ensuring transparency and reliability.
        <br />
        <img
          src="/blog_images/Blog1/1.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        <strong>2. Advanced SQL Capabilities</strong>
        <br />
        Our models are trained to generate{" "}
        <strong>State-of-the-Art SQL code</strong>
        from natural language inputs. You can query over 300+ metrics available
        on our platform{" "}
        <a
          href="https://www.stockbuzz.ai/Metrics"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            marginLeft: "5px",
          }}
        >
          here
        </a>
        , or create custom metrics using plain English. Technical users can even
        edit and execute the SQL code independently for further customization.
        <br />
        <img
          src="/blog_images/Blog1/2.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        <strong>3. Detailed Thinking</strong>
        <br />
        Our models employ a step-by-step reasoning process to iterate through
        the database before generating SQL code. Investors can view the
        reasoning behind each answer by clicking the "i" button above the SQL
        code, providing transparency and confidence in the model's approach.
        <br />
        <img
          src="/blog_images/Blog1/3.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        <strong>4. Interactive Table Results</strong>
        <br />
        Based on the generated SQL, our screener fetches companies that meet
        your specified criteria along with key metrics. You can click on a
        company's symbol to access a detailed analysis page or download the
        table for further investigation.
        <br />
        <img
          src="/blog_images/Blog1/4.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>
      <Paragraph>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            <strong>Company Detail Page</strong>: Investors can quickly evaluate
            individual companies by accessing financial reports, company
            profiles, and the latest news—all consolidated for ease of use{" "}
            <a
              href="https://www.stockbuzz.ai/MELI"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#1890ff",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              here
            </a>{" "}
            .
          </li>
        </ul>

        <img
          src="/blog_images/Blog1/5.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        <ul style={{ paddingLeft: "20px" }}>
          <li>
            <strong>Key Metrics</strong>: Compare companies based on key metrics
            like revenue, income, and EPS.
          </li>
          <img
            src="/blog_images/Blog1/6.png"
            alt="Advanced SQL Capabilities"
            style={{
              width: "100%",
              maxWidth: "100%",
              marginTop: "2%",
              marginBottom: "2%",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
          <li>
            <strong>Price Chart</strong>: Visualize the price performance of
            companies over the last 52 weeks. To normalize the comparison, all
            companies start from the same point, allowing for easy trend
            analysis.
          </li>
        </ul>
        <img
          src="/blog_images/Blog1/7.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        {" "}
        <strong>5. SQL-Driven Responses</strong>
        <br />
      </Paragraph>

      <Paragraph>
        Our models are designed not only to fetch data but also to provide a
        <strong>comprehensive analysis</strong> that aids in decision-making. By
        scanning through financial reports, company profiles, and the latest
        market news, our answers are grounded in data to help you shortlist
        companies for further research.
        <br />
        <img
          src="/blog_images/Blog1/8.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Title level={4} style={{ marginTop: "5%" }}>
        Why This Matters to You
      </Title>

      <Paragraph>
        In today’s competitive markets, having access to detailed, credible, and
        transparent data can be the key to staying ahead. Our Stock Screener
        simplifies complex financial data, empowering investors to make smarter,
        faster decisions with confidence.
      </Paragraph>

      <Title level={4} style={{ marginTop: "5%" }}>
        Feedback is Always Welcome
      </Title>

      <Paragraph>
        We're constantly improving to provide the best user experience. Your
        feedback helps us grow and refine our tools. Share your thoughts and
        suggestions to make this platform even better.
      </Paragraph>
    </div>
  );
};

export default Blog1;
