import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Table,
  Radio,
  Tabs,
  Select,
  Divider,
  List,
  Form,
  Tag,
  Popover,
  Collapse,
} from "antd";
import "./DetailPage.css";
import {
  FilePdfOutlined,
  DownOutlined,
  UpOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import moment from "moment";

import GaugeComponent from "react-gauge-component";

import { Sparklines, SparklinesLine } from "react-sparklines";
import { Tiny, Scatter } from "@ant-design/plots";

import axios from "axios";
import { ProCard, StatisticCard } from "@ant-design/pro-components";
import {
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ReferenceDot,
  Bar,
  BarChart,
} from "recharts";

import { useParams } from "react-router";

import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

export const clean_columns_all = {
  date: "Date",
  symbol: "Symbol",
  reportedCurrency: "Reported Currency",
  cik: "CIK",
  fillingDate: "Filling Date",
  acceptedDate: "Accepted Date",
  calendarYear: "Calendar Year",
  period: "Period",
  revenue: "Revenue",
  costOfRevenue: "Cost of Revenue",
  grossProfit: "Gross Profit",
  grossProfitRatio: "Gross Profit Ratio",
  ResearchAndDevelopmentExpenses: "R&D Expenses",
  GeneralAndAdministrativeExpenses: "Admin. Expenses",
  SellingAndMarketingExpenses: "Selling & Market. Expenses",
  sellingGeneralAndAdministrativeExpenses: "Selling, Gen. and Admin Expenses",
  otherExpenses: "Other Expenses",
  operatingExpenses: "Operating Expenses",
  costAndExpenses: "Cost and Expenses",
  interestExpense: "Interest Expense",
  depreciationAndAmortization: "Depreciation and Amortization",
  EBITDA: "EBITDA",
  EBITDARatio: "EBITDA Ratio",
  operatingIncome: "Operating Income",
  operatingIncomeRatio: "Operating Income Ratio",
  totalOtherIncomeExpensesNet: "Total Other Income/Expenses Net",
  incomeBeforeTax: "Income Before Tax",
  incomeBeforeTaxRatio: "Income Before Tax Ratio",
  incomeTaxExpense: "Income Tax Expense",
  netIncome: "Net Income",
  netIncomeRatio: "Net Income Ratio",
  eps: "Earnings per Share (EPS)",
  EPSDiluted: "Earnings per Share (EPS, Diluted)",
  weightedAverageShsOut: "Weighted Average Shares Outstanding",
  weightedAverageShsOutDil: "Weighted Average Shares Outstanding (Diluted)",
  link: "Link",
  finalLink: "Final Link",
  interestincome: "Interest Income",
  deferredIncomeTax: "Deferred Income Tax",
  stockBasedCompensation: "Stock-Based Compensation",
  changeInWorkingCapital: "Change in Working Capital",
  accountsReceivables: "Accounts Receivables",
  inventory: "Inventory",
  accountsPayables: "Accounts Payables",
  otherWorkingCapital: "Other Working Capital",
  otherNonCashItems: "Other Non-Cash Items",
  netCashProvidedByOperatingActivites: "netCashProvidedByOperatingActivites",
  investmentsInPropertyPlantAndEquipment: "Investments in PPE",
  acquisitionsNet: "Acquisitions (Net)",
  purchasesOfInvestments: "Purchases of Investments",
  salesMaturitiesOfInvestments: "Sales/Maturities of Investments",
  otherInvestingActivites: "Other Investing Activities",
  netCashUsedForInvestingActivites: "Cash from Investing Activity",
  debtRepayment: "Debt Repayment",
  commonStockIssued: "Common Stock Issued",
  commonStockRepurchased: "Common Stock Repurchased",
  dividendsPaid: "Dividends Paid",
  otherFinancingActivites: "Other Financing Activities",
  netCashUsedProvidedByFinancingActivities: "Cash from Financing Activity",
  effectOfForexChangesOnCash: "Effect of Forex Changes on Cash",
  netChangeInCash: "Net Change in Cash",
  cashAtEndOfPeriod: "Cash at End of Period",
  cashAtBeginningOfPeriod: "Cash at Beginning of Period",
  operatingCashFlow: "Cash from Operating Activity",
  capitalExpenditure: "Capital Expenditure",
  freeCashFlow: "Free Cash Flow",
  cashAndCashEquivalents: "Cash and Cash Equivalents",
  shortTermInvestments: "Short-Term Investments",
  cashAndShortTermInvestments: "Cash and Short-Term Investments",
  netReceivables: "Net Receivables",
  otherCurrentAssets: "Other Current Assets",
  totalCurrentAssets: "Total Current Assets",
  propertyPlantEquipmentNet: "PPE(Net)",
  goodwill: "Goodwill",
  intangibleAssets: "Intangible Assets",
  otherAssets: "Other Assets",
  totalAssets: "Total Assets",
  accountPayables: "Account Payables",
  shortTermDebt: "Short-Term Debt",
  otherCurrentLiabilities: "Other Current Liabilities",
  totalCurrentLiabilities: "Total Current Liabilities",
  longTermDebt: "Long-Term Debt",
  otherLiabilities: "Other Liabilities",
  totalLiabilities: "Total Liabilities",
  commonStock: "Common Stock",
  retainedEarnings: "Retained Earnings",
  totalStockholdersEquity: "Total Stockholders' Equity",
  totalLiabilitiesAndStockholdersEquity:
    "Total Liabilities and Stockholders' Equity",

  currentRatio: "Current Ratio",
  quickRatio: "Quick Ratio",
  cashRatio: "Cash Ratio",
  daysOfSalesOutstanding: "Days of Sales Outstanding",
  daysOfInventoryOutstanding: "Days of Inventory Outstanding",
  operatingCycle: "Operating Cycle",
  daysOfPayablesOutstanding: "Days of Payables Outstanding",
  cashConversionCycle: "Cash Conversion Cycle",
  grossProfitMargin: "Gross Profit Margin",
  operatingProfitMargin: "Operating Profit Margin",
  pretaxProfitMargin: "Pretax Profit Margin",
  netProfitMargin: "Net Profit Margin",
  effectiveTaxRate: "Effective Tax Rate",
  returnOnAssets: "Return on Assets",
  returnOnEquity: "Return on Equity",
  returnOnCapitalEmployed: "Return on Capital Employed",
  netIncomePerEBT: "Net Income per EBT",
  ebtPerEbit: "EBT per EBIT",
  ebitPerRevenue: "EBIT per Revenue",
  debtRatio: "Debt Ratio",
  debtEquityRatio: "Debt/Equity Ratio",
  longTermDebtToCapitalization: "Long-Term Debt to Capitalization",
  totalDebtToCapitalization: "Total Debt to Capitalization",
  interestCoverage: "Interest Coverage",
  cashFlowToDebtRatio: "Cash Flow to Debt Ratio",
  companyEquityMultiplier: "Company Equity Multiplier",
  receivablesTurnover: "Receivables Turnover",
  payablesTurnover: "Payables Turnover",
  inventoryTurnover: "Inventory Turnover",
  fixedAssetTurnover: "Fixed Asset Turnover",
  assetTurnover: "Asset Turnover",
  operatingCashFlowPerShare: "Operating Cash Flow per Share",
  freeCashFlowPerShare: "Free Cash Flow per Share",
  cashPerShare: "Cash per Share",
  payoutRatio: "Payout Ratio",
  operatingCashFlowSalesRatio: "Operating Cash Flow/Sales Ratio",
  freeCashFlowOperatingCashFlowRatio:
    "Free Cash Flow/Operating Cash Flow Ratio",
  cashFlowCoverageRatios: "Cash Flow Coverage Ratios",
  shortTermCoverageRatios: "Short-Term Coverage Ratios",
  capitalExpenditureCoverageRatio: "Capital Expenditure Coverage Ratio",
  dividendPaidAndCapexCoverageRatio: "Dividend Paid and Capex Coverage Ratio",
  dividendPayoutRatio: "Dividend Payout Ratio",
  priceBookValueRatio: "Price/Book Value Ratio",
  priceToBookRatio: "Price-to-Book Ratio",
  priceToSalesRatio: "Price-to-Sales Ratio",
  priceEarningsRatio: "Price/Earnings Ratio",
  priceToFreeCashFlowsRatio: "Price-to-Free Cash Flows Ratio",
  priceToOperatingCashFlowsRatio: "Price-to-Operating Cash Flows Ratio",
  priceCashFlowRatio: "Price/Cash Flow Ratio",
  priceEarningsToGrowthRatio: "Price/Earnings to Growth Ratio",
  priceSalesRatio: "Price/Sales Ratio",
  dividendYield: "Dividend Yield",
  enterpriseValueMultiple: "Enterprise Value Multiple",
  priceFairValue: "Price/Fair Value",
};

const AnalystGaugeChart = ({ symbol }) => {
  const [loading, setLoading] = useState(true);
  const [analystData, setAnalystData] = useState({
    strongBuy: 0,
    buy: 0,
    hold: 0,
    sell: 0,
    strongSell: 0,
    totalAnalysts: 0,
  });

  const fetchAnalystData = async () => {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/analyst-stock-recommendations/${symbol}?apikey=261c92b3a1319563e8e71c6e92edd3d9`
      );
      const data = await response.json();
      const latest = data[0];
      if (latest) {
        setAnalystData({
          strongBuy: latest.analystRatingsStrongBuy || 0,
          buy: latest.analystRatingsBuy || 0,
          hold: latest.analystRatingsHold || 0,
          sell: latest.analystRatingsSell || 0,
          strongSell: latest.analystRatingsStrongSell || 0,
          totalAnalysts:
            (latest.analystRatingsStrongBuy || 0) +
            (latest.analystRatingsBuy || 0) +
            (latest.analystRatingsHold || 0) +
            (latest.analystRatingsSell || 0) +
            (latest.analystRatingsStrongSell || 0),
        });
      }
    } catch (error) {
      console.error("Failed to fetch analyst data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalystData();
  }, []);

  const { strongBuy, buy, hold, sell, strongSell, totalAnalysts } = analystData;

  const totalRecommendations = strongBuy + buy + hold + sell + strongSell;

  // Calculate percentage position
  const percent = totalRecommendations
    ? (strongBuy * 1 + buy * 0.75 + hold * 0.5 + sell * 0.25 + strongSell * 0) /
      totalRecommendations
    : 0.5;

  const subArcs = [
    { limit: 20, color: "#FF5F6D", tooltip: { text: "Strong Sell" } },
    { limit: 40, color: "#FFA07A", tooltip: { text: "Sell" } },
    { limit: 60, color: "#FFC371", tooltip: { text: "Hold" } },
    { limit: 80, color: "#A0D911", tooltip: { text: "Buy" } },
    { color: "#52C41A", tooltip: { text: "Strong Buy" } },
  ];

  const recommendations = [
    { label: "Strong Sell", value: strongSell, color: "#FF5F6D" },
    { label: "Sell", value: sell, color: "#FFA07A" },
    { label: "Hold", value: hold, color: "#FFC371" },
    { label: "Buy", value: buy, color: "#A0D911" },
    { label: "Strong Buy", value: strongBuy, color: "#52C41A" },
  ];

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h3
        style={{
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Analyst Recommendations
      </h3>
      {/* <p
        style={{
          marginBottom: "20px",
          fontSize: "14px",
          color: "#555",
        }}
      >
        Based on {totalAnalysts} analyst ratings for {companyName} in the last
        30 days
      </p> */}

      {loading ? (
        <Spin />
      ) : (
        <div style={{ width: "100%", height: "200px", position: "relative" }}>
          <GaugeComponent
            type="semicircle"
            value={percent * 100}
            minValue={0}
            maxValue={100}
            arc={{
              width: 0.2,
              padding: 0.005,
              cornerRadius: 1,
              subArcs,
            }}
            pointer={{
              color: "#464A4F",
              length: 0.8,
              width: 15,
            }}
            labels={{
              valueLabel: {
                formatTextValue: (value) => `${value.toFixed(2)}%`,
              },
              tickLabels: {
                type: "outer",
                ticks: [
                  { value: 0, label: "Strong Sell" },
                  { value: 25, label: "Sell" },
                  { value: 50, label: "Hold" },
                  { value: 75, label: "Buy" },
                  { value: 100, label: "Strong Buy" },
                ],
              },
            }}
          />
        </div>
      )}

      <List
        size="small"
        dataSource={recommendations.reverse()} // Reverse the order
        renderItem={(item) => (
          <List.Item style={{ padding: "8px 0" }}>
            <Text
              strong
              style={{
                fontSize: "14px",
                color: item.color, // Add color for visual clarity
              }}
            >
              {item.label}:
            </Text>
            <Text
              style={{
                marginLeft: "8px",
                fontSize: "14px",
                color: "#555",
              }}
            >
              {item.value}
            </Text>
          </List.Item>
        )}
      />
    </div>
  );
};

const CompanyInfoCard = ({ symbol }) => {
  const [companyInfo, setCompanyInfo] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch company data from API
  const fetchCompanyInfo = async () => {
    const apiKey = "261c92b3a1319563e8e71c6e92edd3d9";
    const apiUrl = `https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=${apiKey}`;
    try {
      const response = await axios.get(apiUrl);
      setCompanyInfo(response.data[0] || {});
      setLoading(false);
    } catch (error) {
      console.error("Error fetching company info:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, [symbol]);

  const stats = [
    {
      label: "Market Cap",
      value: (companyInfo.mktCap / 1000000000)?.toFixed(1),
      prefix: "$",
      suffix: "Bn.",
    },
    { label: "Current Price", value: companyInfo.price, prefix: "$" },
    { label: "52W: High-Low ", value: `${companyInfo.range}`, prefix: "$" },
    {
      label: "Intrinsic Value(DCF)",
      value: companyInfo.dcf?.toFixed(1),
      prefix: "$",
    },
    { label: "Book Value", value: companyInfo.bookValue },
    { label: "Dividend Yield", value: `${companyInfo.lastDiv || 0}%` },
    { label: "ROE", value: "-" },
    { label: "Face Value", value: "-" },
    { label: "Price to Sales", value: "-"},
    { label: "ROCE Prev Yr", value: "-" },
    { label: "Promoter Holding", value: "-" },
    { label: "Sales Growth", value:  "-"},
  ];

  return (
    <Card
      style={{
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        padding: "1px",
        borderRadius: "12px",
        backgroundColor: "#ffffff",
      }}
    >
      <Title level={3}>
        {loading
          ? "Loading..."
          : companyInfo.companyName + "(" + symbol + ")" || "Company Info"}
      </Title>
      <Row gutter={[8, 8]}>
        {stats.map((stat, index) => (
          <Col span={8} key={index}>
            <Card
              bordered={false}
              style={{
                backgroundColor: "#f3f4f6",
                borderRadius: "8px",
                textAlign: "center",
                padding: "0px",
                height: "100%",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  color: "#6c757d",
                  marginBottom: "2px",
                  fontWeight: 500,
                }}
              >
                {stat.label}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#212529",
                }}
              >
                {stat.prefix || ""}
                {stat.value || "-"}
                {stat.suffix || ""}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

const DemoTinyLinePrice = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: new Date(entry.date).toLocaleString("en-US", {
      month: "short",
      year: "2-digit",
    }), // Format the date to "Month'Year"
    y: entry.price, // Use price for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,
    autoFit: true,
    smooth: true,

    theme: {
      styleSheet: {
        brandColor: "#068C38", // Specify the color for the line chart
      },
    },
    lineStyle: {
      stroke: "#068C38",
      lineWidth: 4,
    },
  };

  return <Tiny.Line {...config} />;
};

const DemoTinyColumnRevenue = ({ data, color = "#957DAD", isMobile }) => {
  // Prepare the data with `x` and `y` fields
  console.log(data);
  const formattedData = data?.map((entry) => ({
    x: entry.name?.substring(0, 4), // Extract year for `x`
    y: entry?.revenue, // Use revenue for `y`
  }));

  console.log(formattedData);
  const yearDictionary = data?.reduce((dict, entry, index) => {
    const year = entry?.name?.toLocaleString().substring(0, 4);

    const formattedDate = year;

    dict[index] = formattedDate;
    return dict;
  }, {});

  const config = {
    data: formattedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,
    style: {
      fill: color, // Apply custom color
      fillOpacity: 0.7,
    },
    tooltip: false,

    columnStyle: {
      stroke: "transparent",
    },
    theme: {
      styleSheet: {
        brandColor: color,
      },
    },
  };

  return (
    <div>
      <Tiny.Column {...config} />
    </div>
  );
};

const DemoTinyColumnProfit = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: entry.name.substring(0, 4), // Extract the year from the date
    y: entry.netIncome, // Use netIncome for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,

    columnStyle: {
      fill: "#068C38", // Default green color for positive values
      stroke: "#068C38",
    },
  };

  return <Tiny.Column {...config} />;
};

export const DemoTinyAreaPE = ({ data }) => {
  // Transform the data structure to match the expected format
  const transformedData = data.map((entry) => ({
    x: entry.name.substring(0, 4), // Extract year from the name field
    y: entry.priceEarningsRatio, // Use priceEarningsRatio for the y-axis
  }));

  const config = {
    data: transformedData,
    xField: "x",
    yField: "y",
    height: 60,

    autoFit: true,
    smooth: true,
    areaStyle: {
      fill: "l(270) 0:#068C38 1:#fff", // Gradient fill for the area
    },
    line: {
      style: {
        stroke: "#068C38",
        lineWidth: 2,
      },
    },
  };

  return <Tiny.Area {...config} />;
};

export function CuteCharts({ stock_id }) {
  const [incomeStatementData, setIncomeStatementData] = useState([]);
  const [ratiosData, setRatiosData] = useState([]);
  const [priceData, setPriceData] = useState([]);

  const [CAGRData, setCAGRData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  let query_stock = stock_id;

  console.log(query_stock);

  const fetchIncomeStatementData = async (query_stock) => {
    try {
      const apiKey = "261c92b3a1319563e8e71c6e92edd3d9";
      const apiUrl = `https://financialmodelingprep.com/api/v3/income-statement/${query_stock}?limit=10&apikey=${apiKey}`;

      const response = await axios.get(apiUrl);

      const incomeStatementData = response.data.map((record) => ({
        date: record.date,
        revenue: record.revenue,
        netIncome: record.netIncome,
      }));

      setIncomeStatementData(incomeStatementData.reverse());
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRatiosData = async (query_stock) => {
    try {
      const apiKey = "261c92b3a1319563e8e71c6e92edd3d9";
      const apiUrl = `https://financialmodelingprep.com/api/v3/ratios/${query_stock}?limit=10&apikey=${apiKey}`;

      const response = await axios.get(apiUrl);

      const ratiosData = response.data.map((record) => ({
        date: record.date,
        priceEarningsRatio: record.priceEarningsRatio,
      }));

      setRatiosData(ratiosData.reverse());
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPriceData = async (query_stock) => {
    try {
      const apiKey = "261c92b3a1319563e8e71c6e92edd3d9";
      const apiUrl = `https://financialmodelingprep.com/api/v3/historical-price-full/${query_stock}?serietype=line&apikey=${apiKey}`;

      const response = await axios.get(apiUrl);

      const today = new Date();
      const tenYearsAgo = new Date();
      tenYearsAgo.setFullYear(today.getFullYear() - 10);

      const priceData = response.data.historical
        .filter((record) => {
          const recordDate = new Date(record.date);
          return (
            recordDate >= tenYearsAgo &&
            recordDate <= today &&
            record.date.endsWith("-01")
          );
        })
        .map((record) => {
          return {
            date: record.date,
            price: record.close,
          };
        });

      setPriceData(priceData.reverse());
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!dataFetched) {
      fetchIncomeStatementData(query_stock);
      fetchRatiosData(query_stock);
      fetchPriceData(query_stock);
    }
  }, [dataFetched]);

  const info = incomeStatementData?.map((incomeStatement, index) => {
    const matchingRatio = ratiosData?.find(
      (ratio) => ratio.date === incomeStatement.date
    );

    return {
      key: index?.toString(),
      date: incomeStatement.date,
      revenue: incomeStatement.revenue,
      netIncome: incomeStatement.netIncome,
      priceEarningsRatio: matchingRatio
        ? matchingRatio.priceEarningsRatio
        : null,
    };
  });

  console.log(info);

  const data = info?.map((item, index) => ({
    key: index?.toString(),
    name: item.date,
    // price: item.price,
    netIncome: item.netIncome,
    revenue: item.revenue,
    priceEarningsRatio: item.priceEarningsRatio,
  }));

  // Calculate CAGR for the given data
  function calculateCAGR(data, years) {
    const initialValue = data[0];
    const finalValue = data[data.length - 1];
    return ((finalValue / initialValue) ** (1 / years) - 1) * 100;
  }

  console.log(incomeStatementData);
  // Calculate CAGR for revenue growth data
  const revenue_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.revenue),
        10
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.revenue),
            10
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-6).map((item) => item.revenue),
          5
        ).toFixed(1) + "%",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-4).map((item) => item.revenue),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          incomeStatementData.slice(-2).map((item) => item.revenue),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for price/earnings ratio data
  const pe_ratio_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        ratiosData.map((item) => item.priceEarningsRatio),
        9
      )
        ? calculateCAGR(
            ratiosData.map((item) => item.priceEarningsRatio),
            9
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value: calculateCAGR(
        ratiosData.map((item) => item.priceEarningsRatio),
        5
      )
        ? calculateCAGR(
            ratiosData.map((item) => item.priceEarningsRatio),
            5
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          ratiosData.slice(-4).map((item) => item.priceEarningsRatio),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          ratiosData.slice(-2).map((item) => item.priceEarningsRatio),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for net income data
  const net_income_growth_data = [
    {
      metric: "10 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.netIncome),
        9
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.netIncome),
            9
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "5 Years",
      value: calculateCAGR(
        incomeStatementData.map((item) => item.netIncome),
        5
      )
        ? calculateCAGR(
            incomeStatementData.map((item) => item.netIncome),
            5
          ).toFixed(1) + "%"
        : "",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          incomeStatementData.slice(-4).map((item) => item.netIncome),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          incomeStatementData.slice(-2).map((item) => item.netIncome),
          1
        ).toFixed(1) + "%",
    },
  ];

  // Calculate CAGR for stock growth data
  console.log(priceData);
  const stock_growth_data = [
    {
      metric: "10 Years",
      value:
        calculateCAGR(
          priceData.map((item) => item.price),
          10
        ).toFixed(1) + "%",
    },
    {
      metric: "5 Years",
      value:
        calculateCAGR(
          priceData.slice(-6).map((item) => item.price),
          5
        ).toFixed(1) + "%",
    },
    {
      metric: "3 Years",
      value:
        calculateCAGR(
          priceData.slice(-4).map((item) => item.price),
          3
        ).toFixed(1) + "%",
    },
    {
      metric: "TTM",
      value:
        calculateCAGR(
          priceData.slice(-2).map((item) => item.price),
          1
        ).toFixed(1) + "%",
    },
  ];

  const listItemStyle = {
    marginTop: "-8%", // Adjust the top margin between the card title and the list items

    marginBottom: "-8%", // Adjust the margin between list items as per your preference
    border: "none", // Remove borders between rows
  };
  const valueStyle = {
    fontWeight: "bold", // Make the item.value bold
  };

  return (
    <div>
      <div>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Rev. Growth" tip="xyz">
              <DemoTinyColumnRevenue data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={revenue_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Price Growth" tip="xyz">
              <DemoTinyLinePrice data={priceData} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={stock_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound Profit Growth" tip="xyz">
              <DemoTinyColumnProfit data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={net_income_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatisticCard title="Compound PE Growth" tip="xyz">
              <DemoTinyAreaPE data={data} />
              <Card style={{ marginTop: "3%" }}>
                <List
                  dataSource={pe_ratio_growth_data}
                  renderItem={(item) => (
                    <List.Item style={listItemStyle}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span style={{ flex: 1 }}>{item.metric}</span>
                        <span style={valueStyle}>{item.value}</span>
                      </div>
                    </List.Item>
                  )}
                  pagination={false}
                  bordered={false}
                />
              </Card>
            </StatisticCard>
          </Col>
        </Row>
      </div>
      <div></div>
    </div>
  );
}

const IncomeStatementTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async (queryStock) => {
      try {
        let apiUrl;
        if (QtrTable) {
          apiUrl = `https://financialmodelingprep.com/api/v3/income-statement/${queryStock}?period=quarter&limit=8&apikey=261c92b3a1319563e8e71c6e92edd3d9`;
        } else if (AnnualTable) {
          apiUrl = `https://financialmodelingprep.com/api/v3/income-statement/${queryStock}?period=annual&limit=8&apikey=261c92b3a1319563e8e71c6e92edd3d9`;
        }

        const response = await axios.get(apiUrl);

        // Format the response data
        const formattedData = response.data.map((record) => ({
          ...record,
          displayPeriod: QtrTable
            ? `${record.period}'${record.calendarYear?.toString().slice(-2)}` // For quarterly: "Q3'24"
            : record.calendarYear, // For annual: "2024"
        }));

        setInfo(formattedData); // Store formatted data
      } catch (error) {
        console.error("Error fetching income statement data:", error);
      }
    };

    fetchData(symbol);
  }, [QtrTable, AnnualTable]);

  const handleExpand = (key) => {
    if (expandedRowKeys.includes(key)) {
      setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period, // Dynamically set the column title
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
      {
        title: "Trend",
        key: "sparkline",
        render: (_, record) => {
          const sparklineData = periods.map((period) =>
            parseFloat(record[period] || 0)
          );
          return (
            <Sparklines data={sparklineData.reverse()}>
              <SparklinesLine
                style={{ stroke: "blue", strokeWidth: 2, fill: "none" }}
              />
            </Sparklines>
          );
        },
      },
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: ["grossProfit", "operatingIncome", "netIncome"].includes(
          item.name
        ), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const treeData = [
    { name: "revenue" },
    { name: "costOfRevenue" },
    { name: "grossProfit" },
    {
      name: "operatingExpenses",
      children: [
        { name: "sellingGeneralAndAdministrativeExpenses" },
        { name: "ResearchAndDevelopmentExpenses" },
        { name: "otherExpenses" },
      ],
    },
    { name: "operatingIncome" },
    { name: "interestExpense" },
    { name: "incomeTaxExpense" },
    { name: "netIncome" },
    { name: "eps" },
  ];
  const rowData = generateRows(treeData);

  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      AnnualStatement();
    } else {
      QtrStatement();
    }
  };

  const AnnualStatement = () => {
    setAnnualTable(true);
    setQtrTable(false);
  };

  const QtrStatement = () => {
    setAnnualTable(false);
    setQtrTable(true);
  };

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Income Statement"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <div className="sample-grid-container">
        <Table
          columns={columns}
          dataSource={rowData}
          pagination={false}
          expandable={{
            rowExpandable: (record) => record.children?.length > 0,
            expandedRowKeys: expandedRowKeys,
            onExpand: (expanded, record) => handleExpand(record.key),
          }}
          rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
          bordered={false}
        />
      </div>
    </ProCard>
  );
};

const CashFlowTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://financialmodelingprep.com/api/v3/cash-flow-statement/${symbol}?period=${
          QtrTable ? "quarter" : "annual"
        }&limit=8&apikey=261c92b3a1319563e8e71c6e92edd3d9`;

        const response = await axios.get(apiUrl);

        const formattedData = response.data.map((record) => ({
          ...record,
          displayPeriod: QtrTable
            ? `${record.period}'${record.calendarYear?.toString().slice(-2)}`
            : record.calendarYear?.toString(),
        }));

        setInfo(formattedData); // Store formatted data
      } catch (error) {
        console.error("Error fetching cash flow data:", error);
      }
    };

    fetchData();
  }, [QtrTable, AnnualTable]);

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      setAnnualTable(true);
      setQtrTable(false);
    } else {
      setAnnualTable(false);
      setQtrTable(true);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: ["netChangeInCash", "freeCashFlow"].includes(item.name), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const rowData = generateRows([
    {
      name: "operatingCashFlow",
      children: [
        { name: "changeInWorkingCapital" },
        { name: "netIncome" },
        { name: "depreciationAndAmortization" },
        { name: "deferredIncomeTax" },
        { name: "otherNonCashItems" },
      ],
    },
    {
      name: "netCashUsedForInvestingActivites",
      children: [
        { name: "investmentsInPropertyPlantAndEquipment" },
        { name: "acquisitionsNet" },
        { name: "purchasesOfInvestments" },
        { name: "salesMaturitiesOfInvestments" },
      ],
    },
    { name: "netCashUsedProvidedByFinancingActivities" },
    { name: "netChangeInCash" },
    { name: "freeCashFlow" },
  ]);

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Cash Flow Statement"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <Table
        columns={columns}
        dataSource={rowData}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.children?.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([...expandedRowKeys, record.key]);
            } else {
              setExpandedRowKeys(
                expandedRowKeys.filter((key) => key !== record.key)
              );
            }
          },
        }}
        rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
        bordered={false}
      />
    </ProCard>
  );
};

const BalanceSheetTable = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [QtrTable, setQtrTable] = useState(true);
  const [AnnualTable, setAnnualTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [activeButton, setActiveButton] = useState("quarter"); // Default to 'quarterly'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://financialmodelingprep.com/api/v3/balance-sheet-statement/${symbol}?period=${
          QtrTable ? "quarter" : "annual"
        }&limit=8&apikey=261c92b3a1319563e8e71c6e92edd3d9`;

        const response = await axios.get(apiUrl);

        const formattedData = response.data.map((record) => ({
          ...record,
          displayPeriod: QtrTable
            ? `${record.period}'${record.calendarYear?.toString().slice(-2)}`
            : record.calendarYear?.toString(),
        }));

        setInfo(formattedData); // Store formatted data
      } catch (error) {
        console.error("Error fetching balance sheet data:", error);
      }
    };

    fetchData();
  }, [QtrTable, AnnualTable]);

  const handleButtonClick = (type) => {
    setActiveButton(type);
    if (type === "annual") {
      setAnnualTable(true);
      setQtrTable(false);
    } else {
      setAnnualTable(false);
      setQtrTable(true);
    }
  };

  const generateColumns = () => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for dynamic headers

    return [
      {
        title: "Attribute",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${(value / 1e9).toFixed(2)}` : ""), // Format as billions with "B"
      })),
    ];
  };

  const generateRows = (data) => {
    const periods = info.map((row) => row.displayPeriod); // Use `displayPeriod` for row mapping

    const mapMetrics = (item) => {
      const row = {
        key: item.name,
        name: clean_columns_all[item.name] || item.name, // Use mapping for display names
        bold: [
          "totalAssets",
          "totalLiabilities",
          "totalStockholdersEquity",
        ].includes(item.name), // Highlight specific rows
      };

      periods.forEach((period) => {
        const matchingRecord = info.find(
          (record) => record.displayPeriod === period
        );
        row[period] = matchingRecord ? matchingRecord[item.name] : null;
      });

      return row;
    };

    return data.map((item) => ({
      ...mapMetrics(item),
      children: item.children ? generateRows(item.children) : null,
    }));
  };

  const columns = generateColumns();
  const rowData = generateRows([
    {
      name: "totalAssets",
      children: [
        {
          name: "totalCurrentAssets",
          children: [
            {
              name: "cashAndShortTermInvestments",
              children: [
                { name: "cashAndCashEquivalents" },
                { name: "shortTermInvestments" },
              ],
            },
            { name: "netReceivables" },
            { name: "inventory" },
          ],
        },
        { name: "propertyPlantEquipmentNet" },
        { name: "goodwill" },
        { name: "intangibleAssets" },
        { name: "otherAssets" },
      ],
    },
    {
      name: "totalLiabilities",
      children: [
        {
          name: "totalCurrentLiabilities",
          children: [
            { name: "accountPayables" },
            { name: "otherCurrentLiabilities" },
            { name: "longTermDebt" },
          ],
        },
      ],
    },
    {
      name: "totalStockholdersEquity",
      children: [{ name: "commonStock" }, { name: "retainedEarnings" }],
    },
  ]);

  return (
    <ProCard
      className="CompanyInfo_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Balance Sheet"
      extra={
        <Button.Group>
          <div style={{ display: "flex", gap: "1px" }}>
            <Button
              className={`Ratios-button ${
                activeButton === "annual" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("annual")}
            >
              Annual
            </Button>
            <Button
              className={`Ratios-button ${
                activeButton === "quarter" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("quarter")}
            >
              Quarter
            </Button>
          </div>
        </Button.Group>
      }
    >
      <Table
        columns={columns}
        dataSource={rowData}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.children?.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([...expandedRowKeys, record.key]);
            } else {
              setExpandedRowKeys(
                expandedRowKeys.filter((key) => key !== record.key)
              );
            }
          },
        }}
        rowClassName={(record) => (record.bold ? "bold-row" : "normal-row")} // Highlight rows conditionally
        bordered={false}
      />
    </ProCard>
  );
};

const StockPriceChart = ({ symbol }) => {
  const [chartData, setChartData] = useState([]);
  const [latestDate, setLatestDate] = useState("");
  const [latestPrice, setLatestPrice] = useState(0);
  const [priceTargets, setPriceTargets] = useState({});

  const fetchData = async () => {
    const apiKey = "261c92b3a1319563e8e71c6e92edd3d9";
    const historicalApiUrl = `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?serietype=line&apikey=${apiKey}`;
    const priceTargetApiUrl = `https://financialmodelingprep.com/api/v4/price-target-consensus?symbol=${symbol}&apikey=${apiKey}`;

    try {
      // Fetch historical data
      const historicalResponse = await axios.get(historicalApiUrl);
      const historicalData = historicalResponse.data.historical
        .slice(0, 365)
        .reverse()
        .map((item) => ({
          date: item.date,
          currentPrice: item.close,
        }));

      const latestPrice =
        historicalData[historicalData.length - 1]?.currentPrice || 0;
      const latestDataDate =
        historicalData[historicalData.length - 1]?.date || "";

      setLatestDate(latestDataDate);
      setLatestPrice(latestPrice);
      setChartData(historicalData);

      // Fetch price target data
      const targetResponse = await axios.get(priceTargetApiUrl);
      setPriceTargets(targetResponse.data[0] || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const maxYValue = Math.max(
    ...chartData.map((d) => d.currentPrice),
    priceTargets.targetHigh || 0
  );

  return (
    <div style={{ margin: "2%" }}>
      <h2 style={{ marginBottom: "16px", textAlign: "center" }}>
        Price Chart with Analyst Estimates
      </h2>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData} margin={{ right: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => date.split("-").slice(1).join("/")}
              interval="preserveEnd"
            />
            <YAxis
              domain={[0, maxYValue * 1.04]} // Scale to 1.2x max price
              label={{
                value: "Price",
                angle: -90,
                position: "insideLeft",
              }}
              tickFormatter={(price) => `$${price.toFixed(2)}`}
            />
            <Tooltip
              formatter={(value, name) => [`$${value.toFixed(2)}`, name]}
              labelFormatter={(label) => `Date: ${label}`}
            />
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              iconSize={10}
              wrapperStyle={{
                paddingLeft: "20px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            />

            <Line
              type="monotone"
              dataKey="currentPrice"
              name="Current Price"
              stroke="#8884d8"
              dot={false}
              strokeWidth={3}
            />

            {/* Add Markers for Analyst Targets */}
            <ReferenceDot
              x={latestDate}
              y={priceTargets.targetHigh}
              r={8}
              fill="green"
              stroke="black"
              label={{
                value: `★ Max Target:$${priceTargets.targetHigh}`,
                position: "right",
                fill: "green",
                fontSize: 12,
              }}
            />
            <ReferenceDot
              x={latestDate}
              y={priceTargets.targetMedian}
              r={8}
              fill="#82ca9d"
              stroke="black"
              label={{
                value: `★ Median Target:$${priceTargets.targetMedian}`,
                position: "right",
                fill: "#82ca9d",
                fontSize: 12,
              }}
            />
            <ReferenceDot
              x={latestDate}
              y={priceTargets.targetLow}
              r={8}
              fill="#FF4500"
              stroke="black"
              label={{
                value: `★ Min Target:$${priceTargets.targetLow}`,
                position: "right",
                fill: "#FF4500",
                fontSize: 12,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

const ratioBuckets = {
  valuation: [
    { name: "Price to Earnings Ratio", field: "priceEarningsRatio" },
    { name: "Price to Sales Ratio", field: "priceToSalesRatio" },
    { name: "Price to Cash Flow Ratio", field: "priceCashFlowRatio" },
    { name: "Price to Book Ratio", field: "priceToBookRatio" },
    { name: "Enterprise Value", field: "enterpriseValueMultiple" },
  ],
  profitability: [
    { name: "Return on Assets (%)", field: "returnOnAssets" },
    { name: "Return on Equity (%)", field: "returnOnEquity" },
    { name: "Gross Profit Margin (%)", field: "grossProfitMargin" },
    { name: "Operating Profit Margin (%)", field: "operatingProfitMargin" },
    { name: "Net Profit Margin (%)", field: "netProfitMargin" },
  ],
  liquidity: [
    { name: "Quick Ratio", field: "quickRatio" },
    { name: "Current Ratio", field: "currentRatio" },
    { name: "Inventory Turnover", field: "inventoryTurnover" },
    { name: "Asset Turnover", field: "assetTurnover" },
  ],
  solvency: [
    { name: "Debt to Equity Ratio", field: "debtEquityRatio" },
    {
      name: "Long-Term Debt to Capitalization",
      field: "longTermDebtToCapitalization",
    },
  ],
};

const Ratios_Card = ({ symbol }) => {
  const [data, setData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("quarter");
  const [activeBucket, setActiveBucket] = useState("valuation");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const period = timePeriod === "annual" ? "annual" : "quarter";
        const url = `https://financialmodelingprep.com/api/v3/ratios/${symbol}?period=${period}&limit=8&apikey=261c92b3a1319563e8e71c6e92edd3d9`;
        const response = await axios.get(url);

        const formattedData = response.data.map((record) => ({
          ...record,
          displayPeriod:
            timePeriod === "quarter"
              ? `${record.period}'${record.date.split("-")[0].slice(-2)}`
              : record.date.split("-")[0],
        }));

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching ratios data:", error);
      }
    };

    fetchData();
  }, [timePeriod]);

  const handleButtonClick = (type) => {
    setTimePeriod(type);
  };

  const generateColumns = () => {
    const periods = data.map((row) => row.displayPeriod);
    return [
      {
        title: "Metric",
        dataIndex: "metric",
        key: "metric",
        width: 300,
      },
      ...periods.map((period) => ({
        title: period,
        dataIndex: period,
        key: period,
        align: "right",
        render: (value) => (value ? `${parseFloat(value).toFixed(2)}` : "-"),
      })),
    ];
  };

  const generateRows = () => {
    const periods = data.map((row) => row.displayPeriod);
    const metrics = ratioBuckets[activeBucket];

    return metrics.map((metric) => {
      const row = { metric: metric.name, key: metric.field };

      periods.forEach((period, index) => {
        const record = data[index];
        row[period] = record ? record[metric.field] : null;
      });

      return row;
    });
  };

  const columns = generateColumns();
  const rows = generateRows();

  return (
    <ProCard
      className="Ratios_Card-shadow"
      style={{ width: "100%", borderColor: "transparent" }}
      title="Ratios"
      extra={
        <Button.Group>
          <Button
            className={`Ratios-button ${
              timePeriod === "annual" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("annual")}
          >
            Annual
          </Button>
          <Button
            className={`Ratios-button ${
              timePeriod === "quarter" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("quarter")}
          >
            Quarterly
          </Button>
        </Button.Group>
      }
    >
      <div style={{ marginBottom: "1%", display: "flex", gap: "6px" }}>
        {Object.keys(ratioBuckets).map((bucket) => (
          <Button
            key={bucket}
            type={activeBucket === bucket ? "primary" : "default"}
            size="small"
            onClick={() => setActiveBucket(bucket)}
          >
            {bucket.charAt(0).toUpperCase() + bucket.slice(1)}
          </Button>
        ))}
      </div>
      <Table
        columns={columns}
        dataSource={rows}
        pagination={false}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys(expanded ? [record.key] : []);
          },
        }}
        rowClassName={(record) =>
          ["enterpriseValueMultiple"].includes(record.metric) ? "bold-row" : ""
        }
        bordered={false}
      />
    </ProCard>
  );
};

const useEarningTranscripts = (stockId) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const transcripts = [];

        for (let i = 0; i < 8; i++) {
          const quarter =
            (currentYear * 4 + Math.floor(new Date().getMonth() / 3) - i) % 4 ||
            4;
          const year = Math.floor(
            (currentYear * 4 + Math.floor(new Date().getMonth() / 3) - i) / 4
          );
          transcripts.push({ quarter, year });
        }

        setData(transcripts);
      } catch (error) {
        console.error("Error fetching earning call metadata:", error);
      }
    };

    fetchData();
  }, [stockId]);

  return data;
};

const useFilingsData = (stockId, type) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/sec_filings/${stockId}?page=0&apikey=261c92b3a1319563e8e71c6e92edd3d9`
        );
        const filteredData = response.data
          .filter((item) => item.type === type)
          .slice(0, 8); // Limit to the latest 8 filings
        setData(filteredData);
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    };

    fetchData();
  }, [stockId, type]);

  return data;
};

const Documents = ({ symbol }) => {
  const stockId = symbol;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const earningCalls = useEarningTranscripts(stockId);
  const k10Filings = useFilingsData(stockId, "10-K");
  const q10Filings = useFilingsData(stockId, "10-Q");
  const announcements = useFilingsData(stockId, "8-K");

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const renderDocumentList = (
    documents,
    dateFormatter,
    isTranscript = false
  ) => {
    const visibleDocuments = expanded ? documents : documents.slice(0, 4);

    return visibleDocuments.map((item, index) => (
      <List.Item key={index}>
        <div style={{ fontSize: "small", color: "gray" }}>
          {dateFormatter(item)}
        </div>
        {isTranscript ? (
          <Button
            onClick={() =>
              navigate(
                `/transcript?stockId=${stockId}&quarter=${item.quarter}&year=${item.year}`
              )
            }
            style={{ marginLeft: "10px", border: "none" }}
          >
            <EyeOutlined
              style={{
                fontSize: "large",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `/transcript?stockId=${item.symbol}&quarter=${item.quarter}&year=${item.year}`
                )
              }
            />
          </Button>
        ) : (
          <Button
            href={item.finalLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "10px", border: "none" }}
          >
            <FilePdfOutlined
              style={{ fontSize: "large", color: "blueviolet" }}
            />
          </Button>
        )}
      </List.Item>
    ));
  };

  return (
    <div style={{ margin: "2px" }}>
      <Card>
        <ProCard title="SEC Filings" style={{ borderColor: "transparent" }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="Earning Call Transcripts">
                <List>
                  {renderDocumentList(
                    earningCalls,
                    (item) => `Q${item.quarter} ${item.year}`,
                    true // Flag to identify earning transcripts
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="10-K (Annual Filings)">
                <List>
                  {renderDocumentList(k10Filings, (item) =>
                    moment(item.fillingDate).format("MMM YYYY")
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="10-Q (Quarterly Filings)">
                <List>
                  {renderDocumentList(q10Filings, (item) =>
                    moment(item.fillingDate).format("MMM YYYY")
                  )}
                </List>
              </ProCard>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <ProCard bordered title="Others(8-K,SC-13G/A, 4)">
                <List>
                  {renderDocumentList(
                    announcements,
                    (item) =>
                      `${item.type} - ${moment(item.fillingDate).format(
                        "MMM D, YYYY"
                      )}`
                  )}
                </List>
              </ProCard>
            </Col>
          </Row>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Button
              style={{
                backgroundColor: "#E8F5E9",
                border: "none",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              onClick={toggleExpanded}
              icon={expanded ? <UpOutlined /> : <DownOutlined />}
            >
              {expanded ? "Show Less" : "Show More"}
            </Button>
          </div>
        </ProCard>
      </Card>
    </div>
  );
};

const Estimates_Card = ({ symbol }) => {
  const [info, setInfo] = useState([]);
  const [actuals, setActuals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [activeButton, setActiveButton] = useState("Revenue");
  const [isQuarterly, setIsQuarterly] = useState(true);

  useEffect(() => {
    fetchData();
  }, [isQuarterly, activeButton]);

  const fetchData = async () => {
    const period = isQuarterly ? "quarter" : "annual";
    const estimatesApiUrl = `https://financialmodelingprep.com/api/v3/analyst-estimates/${symbol}?period=${period}&limit=30&apikey=261c92b3a1319563e8e71c6e92edd3d9`;
    const actualsApiUrl = `https://financialmodelingprep.com/api/v3/income-statement/${symbol}?period=${period}&limit=12&apikey=261c92b3a1319563e8e71c6e92edd3d9`;

    try {
      const [estimatesResponse, actualsResponse] = await Promise.all([
        axios.get(estimatesApiUrl),
        axios.get(actualsApiUrl),
      ]);

      const today = new Date();
      const sortedEstimates = estimatesResponse.data.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      // Find the closest date to today in estimates data
      const closestIndex = sortedEstimates.findIndex(
        (item) => new Date(item.date) >= today
      );

      // Select 8 items before and after today's date
      const filteredEstimates = sortedEstimates.slice(
        Math.max(0, closestIndex - 8),
        Math.min(sortedEstimates.length, closestIndex + 4)
      );

      setInfo(filteredEstimates);
      setActuals(actualsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (info.length && actuals.length) {
      const formattedData = info.map((estimate) => {
        const estimateDate = new Date(estimate.date);

        const matchedActual = actuals.find((item) => {
          const actualDate = new Date(item.date);
          const differenceInDays = Math.abs(
            (actualDate - estimateDate) / (1000 * 60 * 60 * 24)
          );
          return differenceInDays <= 3; // Match if within ±3 days
        });

        const period = isQuarterly
          ? `Q${Math.ceil(
              Number(estimate.date.substring(5, 7)) / 3
            )}'${estimate.date.substring(2, 4)}`
          : estimate.date.substring(0, 4);

        const highLowActual = {
          Revenue: {
            Avg: estimate.estimatedRevenueAvg / 1e9,
            actualValue: matchedActual?.revenue / 1e9 || null,
          },
          Earnings: {
            Avg: estimate.estimatedNetIncomeAvg / 1e9,
            actualValue: matchedActual?.netIncome / 1e9 || null,
          },
          EPS: {
            Avg: estimate.estimatedEpsAvg,
            actualValue: matchedActual?.eps || null,
          },
        };

        const { Avg, actualValue } = highLowActual[activeButton];

        return {
          period,
          Avg: Avg ? Avg.toFixed(1) : null,
          Actual: actualValue ? actualValue.toFixed(1) : null,
        };
      });

      // Sort in ascending order of period
      setChartData(
        formattedData?.sort((a, b) => {
          const dateA = new Date(
            a.period.replace("Q", "-01-").replace("'", "-")
          );
          const dateB = new Date(
            b.period.replace("Q", "-01-").replace("'", "-")
          );
          return dateA - dateB;
        })
      );
    }
  }, [info, actuals, activeButton, isQuarterly]);

  // Dynamically calculate the chart height
  const chartHeight = 300;

  const maxYValue = Math.max(
    ...chartData.map((item) => Math.max(item.Avg || 0, item.Actual || 0))
  );

  const minYValue = Math.min(
    ...chartData.map((item) => Math.min(item.Avg || 0, item.Actual || 0))
  );

  return (
    <ProCard
      title="Estimates vs Actuals"
      extra={
        <Button.Group>
          <Button
            type={isQuarterly ? "primary" : "default"}
            onClick={() => setIsQuarterly(true)}
          >
            Quarterly
          </Button>
          <Button
            type={!isQuarterly ? "primary" : "default"}
            onClick={() => setIsQuarterly(false)}
          >
            Annual
          </Button>
        </Button.Group>
      }
      style={{
        marginBottom: "20px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        {["Revenue", "Earnings", "EPS"].map((button) => (
          <Button
            key={button}
            type={activeButton === button ? "primary" : "default"}
            onClick={() => setActiveButton(button)}
            style={{
              marginRight: "8px",
              fontWeight: activeButton === button ? "bold" : "normal",
            }}
          >
            {button}
          </Button>
        ))}
      </div>
      {chartData.length > 0 && (
        <ResponsiveContainer width="95%" height={chartHeight}>
          <BarChart data={chartData}>
            <XAxis dataKey="period" />
            <YAxis domain={[maxYValue * 1.1, maxYValue * 1.1]} />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar
              dataKey="Avg"
              fill="#A9A9A9"
              name={`Forecasted ${activeButton}`}
            />
            <Bar
              dataKey="Actual"
              fill="#0096FF"
              name={`Actual ${activeButton}`}
            />
            {/* Highlight future dates */}
            <ReferenceArea
              x1={
                chartData.find((item) => !item.Actual)?.period ||
                chartData[0]?.period
              }
              x2={chartData[chartData.length - 1]?.period}
              strokeOpacity={0.6}
              fill="#f0f8ff" // Light blue pastel
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </ProCard>
  );
};

const CompanyPage = () => {
  const { symbol } = useParams();
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after data is fetched
    }, 400); // Adjust the duration as per your actual data loading time

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  if (loading) {
    // Display spinner while loading
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Row gutter={16}>
        {/* Left Column: Company Overview */}
        <Col span={14}>
          <CompanyInfoCard symbol={symbol} />
        </Col>

        {/* Right Column: Placeholder for Visualization */}
        <Col span={10}>
          <AnalystGaugeChart symbol={symbol} />
        </Col>
      </Row>
      <div style={{ padding: "1%" }}>
        <StockPriceChart symbol={symbol} />
      </div>
      {/* <PeerComparison /> */}
      <IncomeStatementTable symbol={symbol} />
      <CuteCharts stock_id={symbol} />
      <BalanceSheetTable symbol={symbol} />
      <CashFlowTable symbol={symbol} />
      <Ratios_Card symbol={symbol} />
      <Estimates_Card symbol={symbol} />
      <Documents symbol={symbol} />
    </div>
  );
};

export default CompanyPage;
