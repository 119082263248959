import React from "react";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const Blog2 = () => {
  return (
    <div
      style={{
        padding: "5% 15%",
        lineHeight: "1.8",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Title level={2} style={{ textAlign: "center", marginBottom: "5%" }}>
        Introducing Stockbuzz.ai - Key Features
      </Title>

      <Paragraph>
        Imagine having a personalized stock screener, detailed company analysis,
        and powerful valuation tools at your fingertips. At{" "}
        <strong>Stockbuzz.ai</strong>, we're here to revolutionize the way you
        invest. Whether you're a seasoned pro or just starting your journey,
        we've got the tools to supercharge your portfolio.
      </Paragraph>

      <Paragraph>
        <strong>1. Stock Screener – Your Investment GPS</strong>
        <br />
        Our AI-powered stock screener translates plain English queries into{" "}
        <strong>state-of-the-art SQL code</strong>. With over 300+ metrics to
        explore, you can screen for the stocks that match your strategy, or even
        create custom metrics tailored to your needs. Technical users, rejoice!
        You can edit and execute SQL code directly to fine-tune your analysis.{" "}
        <a
          href="https://www.stockbuzz.ai/Metrics"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            marginLeft: "5px",
          }}
        >
          Check it out here.
        </a>
        <br />
        <br />
        <img
          src="/blog_images/Blog2/Blog2-1.png"
          alt="Advanced SQL Capabilities"
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Paragraph>

      <Paragraph>
        <strong>2. Dive into Detailed Company Analysis</strong>
        <br />
        Get to know the companies you invest in like never before. From
        financial reports and earnings transcripts to the latest news, we bring
        everything together in one place.{" "}
        <a
          href="https://www.stockbuzz.ai/MELI"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            marginLeft: "5px",
          }}
        >
          Explore an example here.
        </a>
      </Paragraph>

      <Paragraph>
        <strong>3. Valuation Made Simple</strong>
        <br />
        Uncover the true value of a stock with our comprehensive{" "}
        <strong>DCF analysis</strong>. Adjust key assumptions like interest
        rates and growth rates using intuitive sliders. It’s valuation, your
        way!
      </Paragraph>

      <Paragraph>
        <strong>4. Company Detail Pages – Insights at a Glance</strong>
        <br />
        Get instant access to charts, key metrics, and price performance
        comparisons. From revenue trends to EPS analysis, everything you need is
        just a click away.{" "}
        <a
          href="https://www.stockbuzz.ai/TSLA"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            marginLeft: "5px",
          }}
        >
          See for yourself here.
        </a>
      </Paragraph>

      <Title level={4} style={{ textAlign: "left", marginBottom: "5%" }}>
        Coming Soon – What’s Next for Stockbuzz.ai?
      </Title>

      <Paragraph>
        <strong>1. Investing Books, Reimagined</strong>
        <br />
        We’re training our models on the wisdom of all the great investing books
        ever written. Soon, you’ll be able to explore concepts and strategies
        inspired by legendary investors and screen stocks that match those
        principles.
      </Paragraph>

      <Paragraph>
        <strong>2. Risk Analysis – Spot the Red Flags</strong>
        <br />
        Say goodbye to guesswork! We’re working on tools to identify potential
        red flags and financial irregularities in company reports, helping you
        steer clear of risky investments.
      </Paragraph>

      <Paragraph>
        <strong>3. Technical Indicators for Traders</strong>
        <br />
        While we shine in fundamental analysis, we’re expanding into the world
        of technical analysis. With 10 indicators already available, our tools
        will soon empower traders with advanced technical screening
        capabilities.{" "}
        <a
          href="https://www.stockbuzz.ai/Metrics"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            marginLeft: "5px",
          }}
        >
          Check the metrics here.
        </a>
      </Paragraph>

      <Title level={4} style={{ marginTop: "5%" }}>
        Share Your Feedback – We’re Listening!
      </Title>

      <Paragraph>
        Your insights and suggestions drive us forward. Got ideas for features
        or improvements? Let us know – together, we’ll make Stockbuzz.ai the
        ultimate tool for investors everywhere.
      </Paragraph>
    </div>
  );
};

export default Blog2;
