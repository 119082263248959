import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as XLSX from "xlsx";
import { columnDefinitionsArray } from "./ChartsTables";
import { notification } from "antd";

const metricsArray = columnDefinitionsArray.map((item, index) => ({
  id: index + 1, // Add an ID field dynamically
  "Column Name": (item["Column Name"] || item["**Column Name**"] || "").replace(
    /\*\*/g,
    ""
  ),
  Description: item["Description"] || item["**Description**"],
  "Sample Value": item["Sample Value"] || item["**Sample Value**"],
  "Measurement Unit": item["Measurement Unit"] || item["**Measurement Unit**"],
}));

const openNotification = (columnName, sampleValue) => {
  notification.success({
    message: "Copied",
    description: `${columnName} = ${sampleValue}`,
    placement: "top", // Adjust the placement if needed
    duration: 2, // Duration in seconds
  });
};

// DataGrid columns
const columns = [
  {
    field: "Column Name",
    headerName: "Metrics",
    width: 200,
    headerClassName: "bold-header",
  },
  {
    field: "Description",
    headerName: "Description",
    width: 300,
    headerClassName: "bold-header",
  },
  {
    field: "Sample Value",
    headerName: "Sample Value",
    width: 180,
    headerClassName: "bold-header",
  },
  {
    field: "Measurement Unit",
    headerName: "Measurement Unit",
    width: 180,
    headerClassName: "bold-header",
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (params) => {
      // Extract the Column Name and Sample Value
      const columnName = params.row["Column Name"];
      const sampleValue = params.row["Sample Value"];
      const textToCopy = `${columnName} = ${sampleValue}`;

      return (
        <CopyToClipboard
          text={textToCopy}
          onCopy={() => openNotification(columnName, sampleValue)} // Show notification
        >
          <Button size="small">Copy</Button>
        </CopyToClipboard>
        // <CopyToClipboard text={textToCopy}>
        //   <Button size="small">Copy</Button>
        // </CopyToClipboard>
      );
    },
  },
];

const Metrics = () => {
  const [search, setSearch] = useState("");

  const filteredRows = metricsArray.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );

  // Export function for Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(metricsArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Metrics");
    XLSX.writeFile(workbook, "Metrics.xlsx");
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Metrics Dashboard</h1>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type="text"
          placeholder="Search Metrics..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            padding: "8px",
            width: "300px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        {/* <Button variant="contained" color="primary" onClick={exportToExcel}>
          Export to Excel
        </Button> */}
      </div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5", // Optional: Background color for headers
              fontWeight: "bold", // Bold font for headers
              fontSize: "16px", // Optional: Adjust font size
              textAlign: "center", // Optional: Center align text
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Ensures header title is bold
            },
            "& .MuiDataGrid-cell": {
              textAlign: "left", // Optional: Align cell content
            },
          }}
        />
      </div>
    </div>
  );
};

export default Metrics;
