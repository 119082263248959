import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Card, Spin, Typography, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const TranscriptPage = () => {
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use navigate hook for navigation

  const stockId = searchParams.get("stockId");
  const quarter = searchParams.get("quarter");
  const year = searchParams.get("year");

  const colorizeTranscript = (content) => {
    const speakerPattern = /^(.*?:)/;
    const numberPattern = /\$\d+(?:,\d+)*(?:\.\d+)?/g;
    const percentagePattern = /\d+(?:\.\d+)?%/g;

    return content.split("\n").map((line, i) => {
      const match = line.match(speakerPattern);

      if (match) {
        const speaker = match[0];
        const coloredSpeaker = `<span style="color: blue; font-weight: bold;">${speaker}</span>`;
        const highlightedLine = line
          .replace(speaker, coloredSpeaker)
          .replace(numberPattern, (num) => `<strong>${num}</strong>`)
          .replace(
            percentagePattern,
            (percent) => `<strong>${percent}</strong>`
          );
        return (
          <Paragraph key={i} style={{ marginBottom: "12px" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: highlightedLine,
              }}
            />
          </Paragraph>
        );
      } else {
        const boldedLine = line
          .replace(numberPattern, (num) => `<strong>${num}</strong>`)
          .replace(
            percentagePattern,
            (percent) => `<strong>${percent}</strong>`
          );
        return (
          <Paragraph key={i} style={{ marginBottom: "12px" }}>
            <span dangerouslySetInnerHTML={{ __html: boldedLine }} />
          </Paragraph>
        );
      }
    });
  };

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/earning_call_transcript/${stockId}?year=${year}&quarter=${quarter}&apikey=261c92b3a1319563e8e71c6e92edd3d9`
        );
        setContent(response.data[0]?.content || "No content available.");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transcript:", error);
        setContent("Failed to load transcript.");
        setLoading(false);
      }
    };

    fetchTranscript();
  }, [stockId, quarter, year]);

  return (
    <Card
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Title level={3} style={{ marginBottom: 0 }}>
            Transcript: {stockId} - Q{quarter} {year}
          </Title>
        </div>
      }
      style={{ margin: "20px", padding: "20px", backgroundColor: "#f9f9f9" }}
    >
      {loading ? (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      ) : (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {content ? (
            colorizeTranscript(content)
          ) : (
            <Paragraph>No data available.</Paragraph>
          )}
        </div>
      )}
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/TSLA")} // Navigate back to the base page
        style={{ marginRight: "10px" }}
      >
        Prev Page
      </Button>
    </Card>
  );
};

export default TranscriptPage;
