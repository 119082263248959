import React, { useState, useEffect } from "react";
import { Card, Row, Col, Typography, Spin } from "antd";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Mock blog data
    const mockData = [
      {
        id: 1,
        title:
          "Revolutionize Your Investment Decisions with Our Advanced Stock Screener",
        category: "Stock Market",
        imageUrl: "/blog_images/Blog1/Blog1.png",
        summary:
          "Discover how our AI-powered stock screener can transform your investment strategies.",
        link: "/blog/ai-stock-screener",
        date: "2d ago",
        stats: { views: 104, comments: 3 },
      },
      {
        id: 2,
        title: "Introducting Stockbuzz.ai - Key Features",
        category: "Stock Market",
        imageUrl: "/blog_images/Blog2/Blog2.png",
        summary:
          "Discover our models to analyze companies, valuation models, state of the art screener and detailed financials of any company.",
        link: "/blog/introducing-stockbuzz.ai",
        date: "2d ago",
        stats: { views: 94, comments: 1 },
      },
    ];

    setTimeout(() => {
      setBlogs(mockData);
      setLoading(false);
    }, 1000); // Simulated loading
  }, []);

  return (
    <div
      style={{
        padding: "5% 15%",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
      }}
    >
      <Title
        level={3}
        style={{
          textAlign: "center",
          marginBottom: "4rem",
          fontFamily: "Arial, sans-serif",
        }}
      >
        Stockbuzz.ai Overview
      </Title>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ) : (
        blogs.map((blog) => (
          <a
            href={blog.link}
            key={blog.id}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Row
              gutter={[24, 24]}
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            >
              {/* Text Column */}
              <Col xs={24} sm={16} lg={18}>
                <div style={{ padding: "10px" }}>
                  <Title
                    level={4}
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    {blog.title}
                  </Title>
                  <Text
                    type="secondary"
                    style={{
                      fontSize: "12px",
                      color: "#888",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    {blog.date} · {blog.stats.views} views ·{" "}
                    {blog.stats.comments} comments
                  </Text>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#555",
                      lineHeight: "1.5",
                    }}
                  >
                    {blog.summary}
                  </p>
                </div>
              </Col>

              {/* Image Column */}
              <Col xs={24} sm={8} lg={6}>
                <img
                  alt={blog.title}
                  src={blog.imageUrl}
                  style={{
                    width: "100%",
                    maxHeight: "150px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Col>
            </Row>
          </a>
        ))
      )}
    </div>
  );
};

export default Blog;
