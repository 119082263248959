import React, { useMemo, useEffect, useState } from "react";
import {
  Layout,
  Card,
  Divider,
  List,
  Skeleton,
  Button,
  message,
  Steps,
  Collapse,
  Typography,
  Progress,
  Spin,
  Row,
  Col,
  Drawer,
  Tooltip,
} from "antd";
import ReactMarkdown from "react-markdown";

import {
  ChartsAndTables,
  CompanyCharts,
  CompanyChartsMultiple,
  DCFFCompany,
} from "./ChartsTables";
import "./App.css";
import {
  ArrowUpOutlined,
  DownCircleTwoTone,
  RightOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  SyncOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  RedoOutlined,
  CopyOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  fetchAgentNameUpdatedAdy,
  StreamComponent,
  fetchSymbolsTable,
  ProgressSteps,
  trivias,
  writeApi,
} from "./UserDataDynamoDB";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { IncomeStatementMetricsChart } from "./ChartsTables";

const { Content } = Layout;
const { Step } = Steps;
const { Panel } = Collapse;
const { Paragraph, Text, Link } = Typography;

const MemoizedChartsAndTables = ({ tableData, question, setFinalQuestion }) => {
  // console.log('question memoized',question)
  // Normalize input_symbols_data
  let input_symbols_data = tableData?.table_data?.replace(/,\s+/g, ",");

  return useMemo(() => {
    // Check for valid router
    if (tableData?.router === "CompanyAnalysis" && question != "") {
      return (
        <CompanyCharts
          question={question}
          symbols={tableData?.entity}
          subrouter={tableData?.subrouter}
        />
      );
    }

    // Skip rendering for specific routers
    if (
      ["OutOfScope", "LLM", "RAG"].includes(tableData?.router) &&
      question != ""
    ) {
      return null;
    }

    // Render ChartsAndTables for SQL router only if input_symbols_data is valid
    if (tableData?.router === "SQL" && question != "") {
      return (
        <>
          {input_symbols_data?.length > 0 ? (
            <div>
              <ChartsAndTables
                input_symbols={input_symbols_data}
                sql_columns={tableData?.extracted_columns?.split("@@@")[1]}
              />
            </div>
          ) : (
            <div style={{ padding: "20px", textAlign: "left" }}>
              <h4 style={{ color: "#0073e6", fontWeight: "bold" }}>
                No Results Available
              </h4>
              <p style={{ color: "#555" }}>
                Sorry, I didn't understand your question fully or couldn't write
                a correct SQL for fetching relevant data. Please try asking the
                question again with slight reformulation. You can learn about my
                capabilities from the documentation here (ToDo).
              </p>
            </div>
          )}
        </>
      );
    }

    // Default case for unsupported routers

    setFinalQuestion("");
    return null;
  }, [tableData, input_symbols_data]); // Add dependencies for memoization
};

// Helper function to extract symbols from a single message
const extractSymbolsFromMessage = (msg) => {
  const symbols = [];
  if (msg.response?.entity?.length) {
    symbols.push(...msg.response.entity);
  } else if (msg.response?.table_data) {
    symbols.push(...msg.response.table_data.split(","));
  }
  return [...new Set(symbols)]; // Remove duplicates
};

// Component to display sources
const SourceList = ({ sources }) => {
  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <h3
        style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "16px" }}
      >
        <span role="img" aria-label="sources">
          📚
        </span>{" "}
        Sources
      </h3>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          paddingBottom: "2%",
          gap: "12px",
        }}
      >
        {sources.map((source, index) => (
          <div
            key={index}
            style={{ flex: "0 0 calc(15% - 8px)", minWidth: "180px" }}
          >
            <a
              href={source.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card
                hoverable
                style={{
                  borderRadius: "6px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <h4
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "8px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {source.title}
                </h4>
                <p style={{ fontSize: "12px", color: "#777" }}>
                  {source.author}
                </p>
                <a
                  href={source.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: "12px",
                    color: "#1a0dab", // Standard blue hyperlink color
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    maxWidth: "100%", // Adjust based on container width
                  }}
                  title={source.url} // Full URL on hover
                >
                  {source.url}
                </a>{" "}
              </Card>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const MainChat = ({
  activeThread,
  skeletonLoading,
  skeletonLLMLoading,
  newMessage,
  setNewMessage,
  messagesEndRef,
  isMobile,
  streamActive,
  setStreamActive,
  user_value_cookie,
  InterruptWrite,
  setInterruptWrite,
  regenerate,
  setRegenerate,
  currentStep,
  setCurrentStep,
  setSkeletonLoading,
  setSkeletonLLMLoading,
  setActiveThread,
  setLoading,
  setNewChat,
}) => {
  const [AgentNameData, setAgentNameData] = useState(null);
  const [RouterData, setRouterData] = useState("");
  const [SymbolsNameData, setSymbolsNameData] = useState([]);
  const [llmResponseData, setLlmResponseData] = useState("test");
  const [RelatedQuestionShow, setRelatedQuestionShow] = useState(true);

  const [isEditing, setIsEditing] = useState({});
  const [editedSQL, setEditedSQL] = useState({});

  function formatSQLQuery(query) {
    const keywords = [
      "SELECT",
      "FROM",
      "WHERE",
      "GROUP BY",
      "ORDER BY",
      "LIMIT",
      "INNER JOIN",
      "LEFT JOIN",
      "RIGHT JOIN",
      "FULL JOIN",
      "UNION",
      "HAVING",
      "ON",
      "WITH",
      "AS",
      "INSERT INTO",
      "VALUES",
      "UPDATE",
      "SET",
      "DELETE",
    ];

    // Regex for SQL keywords
    const keywordRegex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");

    // Helper functions to encode and decode strings and column names
    const encodeTokens = (line) =>
      line.replace(
        /'[^']*'|`[^`]*`|([a-zA-Z_][a-zA-Z0-9_]*(?=\s*=|\s+AS|\s+AND|\s+OR|,|\(|\)|\s))/g,
        (match) => `##${btoa(match)}##`
      );

    const decodeTokens = (line) =>
      line.replace(/##([^#]*)##/g, (match, group) => atob(group));

    // Format the query
    const formattedQuery = query
      .replace(/\s+/g, " ") // Normalize whitespace to single spaces
      .replace(keywordRegex, "\n$1") // Add newline before keywords
      .replace(/,(?!\s)/g, ", ") // Ensure commas are followed by a space
      .split("\n") // Split into lines for processing
      .map((line) => {
        const encodedLine = encodeTokens(line.trim()); // Encode tokens

        // Handle SELECT clause: Put items on their own lines
        if (encodedLine.startsWith("SELECT")) {
          const rest = encodedLine.slice("SELECT".length).trim();
          const items = rest.split(",").map((item) => item.trim());
          const formattedItems = items.map((item) => `    ${item}`).join(",\n");
          return `SELECT\n${formattedItems}`;
        }

        // Handle WHERE clause: Ensure conditions are on their own lines
        if (encodedLine.startsWith("WHERE")) {
          const rest = encodedLine.slice("WHERE".length).trim();
          const conditions = rest.split(/(AND|OR)/gi); // Split by logical operators
          const formattedConditions = conditions
            .map((cond) => cond.trim())
            .filter(Boolean)
            .join(" "); // Rejoin conditions without breaking words
          return `WHERE\n    ${decodeTokens(formattedConditions)}`;
        }

        // Handle GROUP BY and ORDER BY: Put items on their own lines
        if (
          encodedLine.startsWith("GROUP BY") ||
          encodedLine.startsWith("ORDER BY")
        ) {
          const keyword = encodedLine.split(" ")[0];
          const rest = encodedLine.slice(keyword.length).trim();
          const items = rest.split(",").map((item) => item.trim());
          const formattedItems = items
            .map((item) => `    ${decodeTokens(item)}`)
            .join(",\n");
          return `${keyword}\n${formattedItems}`;
        }

        return decodeTokens(encodedLine); // Default case for other lines
      })
      .join("\n") // Join lines back together
      .replace(/\s{2,}/g, " "); // Remove excessive whitespace

    return formattedQuery.trim(); // Remove leading/trailing whitespace
  }

  const handleToggleEdit = (index, sqlCode) => {
    setIsEditing((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    // If switching from edit to view mode, save the edited SQL
    if (isEditing[index]) {
      handleSaveSQL(index);
    } else {
      // Initialize the SQL editor with the current code when entering edit mode
      setEditedSQL((prev) => ({
        ...prev,
        [index]: sqlCode,
      }));
    }
  };

  // Update SQL code for a specific message
  const handleSQLChange = (e, index) => {
    setEditedSQL((prev) => ({
      ...prev,
      [index]: e.target.value,
    }));
  };

  // Save the edited SQL (you can implement further logic like API calls here)
  const getModifiedMessage = (message, activeThread) => {
    const baseMessage = message.replace(/\(modified.*\)$/, "").trim(); // Remove any existing (Modified) suffix
    const existingModifiedCount = activeThread.messages.filter(
      (msg) =>
        msg.message.startsWith(baseMessage) &&
        msg.message.match(/\(modified.*\)$/)
    ).length;

    if (existingModifiedCount > 0) {
      return `${baseMessage} (modified ${existingModifiedCount + 1})`;
    }

    return `${baseMessage} (modified 1)`; // Default to (Modified 1) if no existing modifications
  };

  const handleSaveSQL = async (index) => {
    const editedQuery = editedSQL[index];
    const modifiedMessage = getModifiedMessage(
      activeThread.messages[index].message,
      activeThread
    );

    try {
      // Call the writeApi function to save the new SQL query
      await writeApi(
        user_value_cookie, // User session or identifier
        setLoading, // Set loading state
        setSkeletonLoading, // Set skeleton loading state
        setSkeletonLLMLoading, // Set LLM skeleton loading state
        setNewChat, // Set new chat state
        modifiedMessage,
        activeThread, // Current active thread
        setActiveThread, // Function to update the thread,
        activeThread.messages[index].message,
        editedQuery // Pass the new SQL query
      );

      setEditedSQL((prev) => ({
        ...prev,
        [index]: null,
      }));

      // Toggle the edit mode off for this message
      setIsEditing((prev) => ({
        ...prev,
        [index]: false,
      }));

      // Display success message
      message.success("SQL query saved and executed successfully!");
    } catch (error) {
      console.error("Error saving SQL query:", error);
      message.error("Failed to save the SQL query.");
    }
  };

  // console.log('activeThread:',activeThread)

  const [latestSymbols, setLatestSymbols] = useState([]);
  const [sources, setSources] = useState([]);

  const [finalQuestion, setFinalQuestion] = useState(""); // State for controlled updates

  // Trigger rendering of charts when the condition is met
  useEffect(() => {
    setFinalQuestion(newMessage); // Update finalQuestion when ready to render charts
  }, [skeletonLoading]);

  // console.log("finalQuestion",finalQuestion)

  // Helper to fetch company profile
  const fetchCompanyProfile = async (symbol) => {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=261c92b3a1319563e8e71c6e92edd3d9`
      );
      const data = await response.json();
      if (data.length > 0) {
        return {
          companyName: data[0].companyName,
          website: data[0].website,
        };
      }
      return { companyName: null, website: null };
    } catch (error) {
      console.error(`Error fetching profile for ${symbol}:`, error);
      return { companyName: null, website: null };
    }
  };

  // Helper to fetch SEC filings for a symbol
  const fetchSecFilings = async (symbol) => {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/sec_filings/${symbol}?page=0&apikey=261c92b3a1319563e8e71c6e92edd3d9`
      );
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error(`Error fetching SEC filings for ${symbol}:`, error);
      return [];
    }
  };

  // Helper to generate sources from filings
  const generateSources = (filings, symbol) => {
    return filings
      .filter((filing) => ["8-K", "10-Q", "10-K"].includes(filing.type)) // Filter specific types
      .sort((a, b) => new Date(b.fillingDate) - new Date(a.fillingDate)) // Sort by date descending
      .slice(0, 5) // Limit to 5 entries
      .map((filing) => {
        const date = new Date(filing.fillingDate);
        const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
        return {
          title: `${symbol.toUpperCase()} (${filing.type})`, // Format title
          author: `Date: ${formattedDate}`, // Use filling date
          url: filing.finalLink, // Use final link
        };
      });
  };

  // Update `latestSymbols` whenever `activeThread` changes
  useEffect(() => {
    if (activeThread?.messages?.length > 0) {
      const latestMessage =
        activeThread.messages[activeThread.messages.length - 1];
      const symbols = extractSymbolsFromMessage(latestMessage).slice(0, 5); // Limit symbols to a maximum of 5
      setLatestSymbols(symbols);
    }
  }, [streamActive, newMessage, activeThread]);

  // Update `sources` dynamically based on `latestSymbols`
  useEffect(() => {
    const updateSources = async () => {
      if (latestSymbols.length > 0) {
        try {
          if (latestSymbols.length === 1) {
            // Single symbol: Fetch company profile and filings
            const [profile, filings] = await Promise.all([
              fetchCompanyProfile(latestSymbols[0]),
              fetchSecFilings(latestSymbols[0]),
            ]);
            const filingSources = generateSources(filings, latestSymbols[0]);
            const sourcesWithCompany = [
              {
                title:
                  profile.companyName ||
                  `${latestSymbols[0].toUpperCase()} - Company Profile`,
                author: `${latestSymbols[0].toUpperCase()}`,
                url: profile.website || "",
              },
              ...filingSources,
            ];
            setSources(sourcesWithCompany);
          } else if (latestSymbols.length > 1) {
            // Multiple symbols: Fetch 1 filing per company (limit to MAX_SYMBOLS)
            const allSources = [];
            for (const symbol of latestSymbols) {
              const filings = await fetchSecFilings(symbol);
              const symbolSources = generateSources(filings, symbol).slice(
                0,
                2
              ); // Limit to 2 filings per symbol
              allSources.push(...symbolSources);
            }
            setSources(allSources);
          } else {
            setSources([]); // No symbols found
          }
        } catch (error) {
          console.error("Error updating sources:", error);
          setSources([]); // Clear sources on error
        }
      }
    };

    updateSources();
  }, [skeletonLLMLoading]); // Trigger only when latestSymbols changes

  let symbolsData = "";

  // console.log("SkeletonLoading Data:",skeletonLoading)
  useEffect(() => {
    const fetchAgentData = async () => {
      if (streamActive && finalQuestion?.length > 0) {
        try {
          const agentData = await fetchAgentNameUpdatedAdy(newMessage);
          // console.log(agentData);

          setAgentNameData(agentData?.agent_name?.Router);
          setRouterData(agentData?.agent_name);

          // console.log(name);
        } catch (error) {
          console.error("Error fetching agent name:", error);
          setAgentNameData(null); // Update state to handle the error
        }
      }
    };

    fetchAgentData(); // Call the async function to perform the fetch operation
    // setRelatedQuestionShow(false);
  }, [streamActive, finalQuestion]); // Dependencies array to control the effect execution

  // console.log("Expert Agent Name:", AgentNameData);
  const handleRewrite = (msg) => {
    // Set streamActive to false initially
    setStreamActive(false);
    setRegenerate(!regenerate);
  };

  const handleShare = () => {
    message.info("Share Chat Feature coming soon");
  };

  // console.log('RelatedQuestionShow:',RelatedQuestionShow)
  const htmlToPlainText = (html) => {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    let text = tempDivElement.textContent || tempDivElement.innerText || "";
    text = text.replace(/\s+/g, " ").trim();
    text = text.replace(/\n/g, " ").replace(/\s{2,}/g, " ");
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const handleCopy = (msg) => {
    const plainText = htmlToPlainText(llmResponseData); // Convert HTML to plain text
    const textToCopy = `Question: ${msg.message}\n\nStockbuzz.ai Answer:\n${plainText}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Message copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy: " + err.message);
      });
  };

  const [triviaVisible, setTriviaVisible] = useState(false);
  const [trivia, setTrivia] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // Function to generate a random trivia
  const getRandomTrivia = () => {
    return trivias[Math.floor(Math.random() * trivias.length)];
  };

  useEffect(() => {
    // Generate a new trivia each time the component mounts
    setTrivia(getRandomTrivia());
  }, [skeletonLLMLoading]); // Empty dependency array ensures it runs once per component lifecycle

  const [isThinking, setIsThinking] = useState(true);

  useEffect(() => {
    setIsThinking(true);

    const timer = setTimeout(() => {
      setIsThinking(false); // Transition to "Detailed Reasoning" after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [finalQuestion]);

  const TriviaComponent = ({ isVisible }) => {
    return (
      <Collapse defaultActiveKey={isVisible ? ["1"] : []} accordion>
        <Panel header="Did You Know?" key="1">
          <Card
            style={{
              background: "#f0f2f5",
              borderRadius: "8px",
              padding: "15px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Paragraph
              style={{
                fontStyle: "italic",
                color: "#555",
                fontSize: "16px",
                whiteSpace: "pre-wrap",
              }}
            >
              {`"${trivia}"`}
            </Paragraph>
          </Card>
        </Panel>
      </Collapse>
    );
  };

  // Function to format plain text into Markdown
  function formatDynamicTextToMarkdown(text) {
    // Replace title
    let formattedText = text.replace(
      /^Decomposing the question:/i,
      "### Decomposing the Question\n\n"
    );

    // Replace numbered sections
    formattedText = formattedText.replace(
      /(\d+)\.\s\*\*(.*?)\*\*:/g,
      "\n$1. **$2**:\n"
    );

    // Format bullet points properly
    formattedText = formattedText
      .replace(/\.\s*-\s/g, "\n   - ") // Ensure new lines before bullets
      .replace(/:\s+-/g, ":\n   -"); // Fix bullet points after a colon

    // Handle inline code blocks for SQL or column names
    formattedText = formattedText.replace(
      /\b([a-z_]+)\b\s*>\s*\b([a-z_]+)\b/g,
      "`$1 > $2`"
    );

    // Prevent wrapping non-code descriptions in code blocks
    formattedText = formattedText.replace(/`(:[^`]*)`/g, "$1");

    // Handle sub-bullets for "Strictly Follow Rules"
    formattedText = formattedText.replace(
      /Strictly Follow Rules:(.*?)\n/g,
      "Strictly Follow Rules:\n$1\n"
    );
    formattedText = formattedText.replace(/-\s(Ensure.*?dates)/g, "   - $1");
    formattedText = formattedText.replace(
      /-\s(Use GROUP.*?complexity)/g,
      "   - $1"
    );

    return formattedText.trim();
  }

  // Format the `thinking` content

  return (
    <Content
      style={{
        padding: "5%",
        minHeight: 280,
        overflow: "auto",
        maxWidth: isMobile ? "100%" : "95%",
        marginLeft: isMobile ? "0" : "2%",
        marginRight: isMobile ? "0" : "2%",
        marginBottom: "10%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          marginTop: isMobile ? "20%" : "5%",
        }}
      >
        {activeThread &&
          activeThread.messages?.map((msg, index) => (
            <div key={index}>
              <Card
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    msg.type === "question" ? "#e9e5e5" : "#e6f7ff",
                  height: "auto",
                  margin: isMobile ? "0 5%" : "0",
                  width: isMobile ? "90%" : "auto",
                  marginBottom: "1%",
                }}
              >
                <h3
                  style={{
                    margin: "0",
                    fontSize: "medium",
                    color: msg.type === "question" ? "#000" : "#1890ff",
                  }}
                >
                  {msg.message}
                </h3>
              </Card>

              {skeletonLoading && index === activeThread.messages.length - 1 ? (
                <div>
                  <ProgressSteps
                    newMessage={newMessage}
                    streamActive={streamActive}
                    AgentNameData={AgentNameData}
                  />
                  {streamActive ? (
                    <TriviaComponent isVisible={true} trivia={trivia} />
                  ) : null}
                  <Skeleton
                    active
                    paragraph={{ rows: 2 }}
                    style={{
                      maxWidth: "100%",
                      alignSelf: "flex-start",
                      padding: "10px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div>
                    {index === activeThread.messages.length - 1 &&
                    latestSymbols?.length > 0 &&
                    finalQuestion != "" ? (
                      <div>
                        {" "}
                        <SourceList sources={sources} />
                      </div>
                    ) : null}
                    {/* SQL Code Display with Edit Functionality */}
                    {msg.response?.sql_code && (
                      <div
                        className="markdown-preview"
                        style={{
                          marginTop: "20px",
                          padding: "16px",
                          backgroundColor: "#f7f9fc", // Light pastel background
                          borderRadius: "12px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          position: "relative",
                        }}
                      >
                        <div
                          className="code-header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              color: "#333",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Below is the SQL code executed against the financial
                            database
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "16px", // Space between buttons
                              marginBottom: "16px",
                            }}
                          >
                            {/* Copy SQL Button */}
                            <button
                              onClick={() =>
                                navigator.clipboard
                                  .writeText(msg.response.sql_code)
                                  .then(() =>
                                    message.success("SQL copied to clipboard!")
                                  )
                                  .catch(() =>
                                    message.error("Failed to copy SQL")
                                  )
                              }
                              style={{
                                backgroundColor: "#7fcdbb", // Pastel green
                                color: "#fff",
                                border: "none",
                                borderRadius: "8px",
                                padding: "10px 16px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                cursor: "pointer",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
                                transition: "all 0.3s ease",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.backgroundColor = "#68bba1")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.backgroundColor = "#7fcdbb")
                              }
                            >
                              Copy SQL
                            </button>

                            {/* Save and Execute / Edit SQL Button */}
                            <button
                              onClick={() => {
                                if (isEditing[index]) {
                                  handleSaveSQL(index); // Save handler
                                } else {
                                  handleToggleEdit(
                                    index,
                                    msg.response.sql_code
                                  ); // Toggle edit
                                }
                              }}
                              disabled={
                                isEditing[index] && !editedSQL[index]?.trim()
                              } // Disable Save if no change
                              style={{
                                backgroundColor: isEditing[index]
                                  ? "#6cb5ff"
                                  : "#ffcc80", // Pastel blue when editing, orange otherwise
                                color:
                                  isEditing[index] && !editedSQL[index]?.trim()
                                    ? "#ccc"
                                    : "#fff", // Gray text when disabled
                                border: "none",
                                borderRadius: "8px",
                                padding: "10px 16px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                cursor:
                                  isEditing[index] && !editedSQL[index]?.trim()
                                    ? "not-allowed"
                                    : "pointer", // No pointer when disabled
                                boxShadow: isEditing[index]
                                  ? "0 4px 8px rgba(0, 0, 0, 0.2)" // Stronger shadow when editing
                                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
                                transition: "all 0.3s ease",
                                opacity:
                                  isEditing[index] && !editedSQL[index]?.trim()
                                    ? 0.6
                                    : 1, // Lower opacity when disabled
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.backgroundColor = isEditing[
                                  index
                                ]
                                  ? "#5293d1" // Darker blue when hovering while editing
                                  : "#ffb74d")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.backgroundColor = isEditing[
                                  index
                                ]
                                  ? "#68bba1"
                                  : "#ffcc80")
                              }
                            >
                              {isEditing[index]
                                ? "Save and Execute"
                                : "Edit SQL"}
                            </button>
                          </div>
                        </div>
                        <span
                          style={{
                            backgroundColor: "#dfe6f2", // Light pastel blue background
                            color: "#4a5a7a", // Darker blue text
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            marginRight: "8px",
                          }}
                        >
                          SQL
                        </span>

                        <div
                          className="code-container"
                          style={{
                            border: "1px solid #d1d9e6", // Light blue border
                            borderRadius: "12px",
                            backgroundColor: "#f9fbfd", // Light pastel background
                            position: "relative",
                            padding: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          {isEditing[index] ? (
                            <div style={{ position: "relative" }}>
                              <textarea
                                value={formatSQLQuery(editedSQL[index])} // Use the prettify function
                                onChange={(e) => handleSQLChange(e, index)}
                                style={{
                                  width: "100%",
                                  height: "300px",
                                  fontFamily: "'Fira Code', monospace", // Clean monospace font
                                  fontSize: "14px",
                                  padding: "16px",
                                  border: "1px solid #c4d3e0",
                                  borderRadius: "8px",
                                  backgroundColor: "#ffffff",
                                  boxShadow:
                                    "inset 0px 4px 10px rgba(0, 0, 0, 0.05)", // Inset shadow
                                  resize: "vertical",
                                  lineHeight: "1.6",
                                  color: "#333333",
                                  overflowY: "auto",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <a
                                  href="https://stockbuzz.ai/Metrics"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    fontSize: "15px",
                                    color: "#0073e6",
                                    textDecoration: "underline",
                                  }}
                                  onMouseOver={(e) =>
                                    (e.target.style.color = "#005bb5")
                                  }
                                  onMouseOut={(e) =>
                                    (e.target.style.color = "#0073e6")
                                  }
                                >
                                  View ALL METRICS
                                </a>
                                <button
                                  onClick={() =>
                                    setIsEditing((prev) => ({
                                      ...prev,
                                      [index]: false,
                                    }))
                                  }
                                  style={{
                                    padding: "8px 20px",
                                    backgroundColor: "grey",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "8px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                    boxShadow:
                                      "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    transition: "background-color 0.3s ease",
                                  }}
                                  onMouseOver={(e) =>
                                    (e.target.style.backgroundColor = "#005bb5")
                                  }
                                  onMouseOut={(e) =>
                                    (e.target.style.backgroundColor = "#0073e6")
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="sql-container">
                              <ReactMarkdown
                                children={`\`\`\`sql\n${formatSQLQuery(
                                  msg.response.sql_code
                                )}\n\`\`\``}
                                components={{
                                  code({
                                    node,
                                    inline,
                                    className,
                                    children,
                                    ...props
                                  }) {
                                    return !inline ? (
                                      <pre
                                        style={{
                                          backgroundColor: "#1e1e1e",
                                          color: "#ffffff",
                                          borderRadius: "8px",
                                          padding: "20px",
                                          fontSize: "14px",
                                          lineHeight: "1.6",
                                          overflowX: "auto",
                                          whiteSpace: "pre-wrap",
                                          margin: "10px 0",
                                          position: "relative",
                                          boxShadow:
                                            "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                        }}
                                      >
                                        <code className={className} {...props}>
                                          {children}
                                        </code>
                                      </pre>
                                    ) : (
                                      <code
                                        style={{
                                          backgroundColor: "#1e1e1e",
                                          color: "#ffffff",
                                          padding: "4px 8px",
                                          borderRadius: "4px",
                                        }}
                                        className={className}
                                        {...props}
                                      >
                                        {children}
                                      </code>
                                    );
                                  },
                                }}
                                
                              />
                              {index === activeThread.messages.length - 1 && (
                                // <Tooltip
                                //   title={
                                //     isThinking
                                //       ? "Hang tight, gathering reasoning..."
                                //       : "Click to explore detailed reasoning"
                                //   }
                                //   placement="top"
                                // >
                                <Button
                                type="primary"
                                onClick={showDrawer}
                                style={{
                                  backgroundColor: "#B3D9FF", // Pastel blue
                                  borderColor: "#B3D9FF",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  padding: "8px 16px",
                                  borderRadius: "8px", // Rounded corners for a friendly look
                                  transition: "all 0.3s ease-in-out", // Smooth hover effects
                                  boxShadow: "0 4px 8px rgba(179, 217, 255, 0.5)", // Soft pastel shadow
                                  color: "#003366", // Dark text for contrast
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  position: "relative", // For the hover effect animation
                                  overflow: "hidden", // Ensures hover effect stays within bounds
                                }}
                                onMouseEnter={(e) => {
                                  e.target.style.backgroundColor = "#A4C8F0"; // Slightly darker pastel blue
                                  e.target.style.borderColor = "#A4C8F0";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.backgroundColor = "#B3D9FF"; // Reset to original pastel blue
                                  e.target.style.borderColor = "#B3D9FF";
                                }}
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.2), transparent)",
                                    transform: "translateX(-100%)",
                                    transition: "transform 0.3s ease-in-out",
                                    zIndex: 0,
                                  }}
                                  className="hover-animation"
                                />
                                {isThinking ? (
                                  <>
                                    <LoadingOutlined
                                      style={{
                                        fontSize: "16px",
                                        animation: "spin 1.5s linear infinite", // Rotating animation
                                        zIndex: 1,
                                      }}
                                    />
                                    <span style={{ zIndex: 1 }}>Thinking...</span>
                                  </>
                                ) : (
                                  <span style={{ zIndex: 1 }}>Detailed Reasoning</span>
                                )}
                              </Button>
                              
                                //                           <style>
                                //                             {`
                                //   @keyframes spin {
                                //     0% { transform: rotate(0deg); }
                                //     100% { transform: rotate(360deg); }
                                //   }
                                // `}
                                //                           </style>
                                //                         </Tooltip>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <Drawer
                      title={
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#0073e6",
                          }}
                        >
                          Detailed Thinking
                        </span>
                      }
                      placement="right"
                      closable={true}
                      mask={false}
                      onClose={toggleDrawer}
                      open={isDrawerOpen}
                      width={500} // Adjust width for better readability
                    >
                      {/* Markdown Preview */}
                      {msg.response.thinking?.length > 0 ? (
                        <MarkdownPreview
                          source={formatDynamicTextToMarkdown(
                            msg.response.thinking
                          )}
                        />
                      ) : null}
                    </Drawer>

                    {finalQuestion?.length>0 && 
                      <div style={{ marginTop: "1%" }}>
                        <MemoizedChartsAndTables
                          tableData={msg.response || ""}
                          question={finalQuestion}
                          setFinalQuestion={setFinalQuestion}
                        />
                      </div>
                    }

                    <Card>
                      <div className="stream-markdown-preview">
                        <StreamComponent
                          question={newMessage}
                          streamActive={streamActive}
                          setStreamActive={setStreamActive}
                          activeThread={activeThread}
                          user_value_cookie={user_value_cookie}
                          AgentNameData={AgentNameData}
                          symbols_sql={msg?.response}
                          symbols_table={SymbolsNameData}
                          setLlmResponseData={setLlmResponseData}
                          setRelatedQuestionShow={setRelatedQuestionShow}
                          InterruptWrite={InterruptWrite}
                          setInterruptWrite={setInterruptWrite}
                          RouterData={RouterData}
                        />
                      </div>
                      <div className="markdown-preview">
                        <MarkdownPreview source={msg.response?.llm_response} />
                      </div>
                    </Card>
                    {index === activeThread.messages.length - 1 &&
                      !streamActive && (
                        <div style={{ marginTop: "10px", textAlign: "left" }}>
                          <Button
                            style={{
                              marginRight: "10px",
                              border: "none", // Removes the border
                              fontSize: "12px", // Smaller text
                            }}
                            icon={<RedoOutlined style={{ fontSize: "16px" }} />} // Smaller icon
                            onClick={() => handleRewrite(msg)}
                          >
                            Regenerate
                          </Button>
                          <Button
                            style={{
                              marginRight: "10px",
                              border: "none", // Removes the border
                              fontSize: "12px", // Smaller text
                            }}
                            icon={<CopyOutlined style={{ fontSize: "16px" }} />} // Smaller icon
                            onClick={() => handleCopy(msg)}
                          >
                            Copy
                          </Button>
                          <Button
                            style={{
                              border: "none", // Removes the border
                              fontSize: "12px", // Smaller text
                              color: "grey", // Grey out the button
                              cursor: "not-allowed", // Show not-allowed cursor
                            }}
                            icon={
                              <ShareAltOutlined
                                style={{ fontSize: "16px", color: "grey" }}
                              />
                            } // Smaller icon
                            onClick={handleShare}
                          >
                            Share
                          </Button>
                        </div>
                      )}
                  </div>
                </>
              )}

              <Divider />
            </div>

            // {msg.response?.relevant_questions?.length > 0 &&
            //   (index < activeThread.messages.length - 1 ||
            //     RelatedQuestionShow) && (
            //     <div>

            //       <Card style={{ marginTop: "1%" }}>
            //         <div
            //           style={{
            //             marginBottom: "1%",
            //             fontWeight: "bold",
            //             fontSize: "large",
            //             color: "black",
            //             display: "flex",
            //             alignItems: "center",
            //           }}
            //         >
            //           Related Questions:
            //         </div>
            //         <List
            //           dataSource={msg.response?.relevant_questions
            //             ?.split("\n")
            //             ?.filter((item) => item.trim() !== "")}
            //           renderItem={(item) => (
            //             <List.Item
            //               style={{
            //                 borderBottom: "none",
            //                 marginBottom:
            //                   index ===
            //                   msg?.response?.relevant_questions
            //                     ?.split("\n")
            //                     .filter((item) => item.trim() !== "").length -
            //                     1
            //                     ? "0px"
            //                     : "-10px",
            //               }}
            //             >
            //               <div
            //                 onClick={() =>
            //                   setNewMessage(item.replace(/^\d+\.\s*/, ""))
            //                 }
            //                 style={{
            //                   cursor: "pointer",
            //                   fontFamily: "Arial",
            //                   fontSize: "15px",
            //                   fontWeight: "normal",
            //                 }}
            //               >
            //                 {item}
            //               </div>
            //             </List.Item>
            //           )}
            //         />
            //       </Card>
            //     </div>
            //   )}
          ))}
        <div ref={messagesEndRef} />
      </div>
    </Content>
  );
};

export default MainChat;
